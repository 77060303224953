import { Box, Divider, Drawer, Option, Select, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeTransactionStatus, getPayoutById } from '../../services/operators';
import { formatDateTime } from '../../utils/dateFormater';
import { getStatusTextColor } from '../../utils/getStatusColor';
import { Payout } from '../../utils/types';
//import CopyToClipboard from 'react-copy-to-clipboard';
//import { LuCopy, LuCopyCheck } from 'react-icons/lu';

const HistoryItemModal = ({
  id,
  open,
  onClose,
  onKeyDown,
}: {
  id: number;
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
  onKeyDown: (event: React.KeyboardEvent | React.MouseEvent) => void;
}) => {
  const [payout, setPayout] = useState<Payout | null>(null);
  const { t } = useTranslation();
  //const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    const fetch = async () => {
      const data = await getPayoutById(id);

      if (data) {
        setPayout(data);
      }
    };
    if (open) {
      fetch();
    }
  }, [open]);
  /*const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };*/

  const handleChangeStatus = async (_event: React.SyntheticEvent | null, newValue: string | null) => {
    if (payout !== null && newValue !== null) {
      await changeTransactionStatus(payout?.id, newValue)
    }
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      <Box role="presentation" onKeyDown={onKeyDown}>
        <Box sx={{ p: 3 }}>
          <Typography sx={{ mb: 3 }} level="h4">
            {t('details_trans_header')}
          </Typography>
          {!payout && (
            <Typography sx={{ mb: 3 }} level="body-md">
              {t('not_found')}
            </Typography>
          )}
          {payout && (
            <>
              <Box>
                <Typography level="title-lg" sx={{ color:'#947EFE', mb: 2 }}>
                  {t('details_trans_name')}
                </Typography>
                <Typography level="body-md" sx={{overflowWrap: 'anywhere'}}>{`Hashed ID: ${payout.hashed_id}`}</Typography>
                <Typography level="body-md">{`ID: ${payout.id}`}</Typography>
                <Typography level="body-md" sx={{overflowWrap:'anywhere'}}>{`Order id: ${payout.order_id}`}</Typography>
                <Typography level="body-md">
                  {`${t('history_table_startDate')}: ${formatDateTime(payout.created_at.toString())}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_endDate')}: ${formatDateTime(payout.updated_at.toString())}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_priceUah', {
                    currency: (localStorage.getItem('base_currency')) || '',
                  })}: ${Number(
                    payout.amount
                  ).toFixed(2)}`}
                </Typography>
                <Typography level="body-md">
                  {`${t('history_table_priceUsdt')}: ${Number(payout.operator_amount).toFixed(2)}`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    mb: 2,
                  }}
                >
                  <Typography level="body-md">{t('history_table_status') + ':'}</Typography>
                  {payout.status === 'EXPIRED' ? (
                    <>
                      <Typography
                        level="body-md"
                        sx={{ color: getStatusTextColor(payout.status) }}
                      >
                        {payout.status}
                      </Typography>
                      <Select
                        placeholder={payout.status}
                        variant="outlined"
                        slotProps={{
                          listbox: {
                            sx: {
                              '--List-padding': '0px',
                              '--ListItem-radius': '0px',
                            },
                          },
                        }}
                        onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
                          handleChangeStatus(_event, newValue);
                        }}
                      >
                        <Option
                          value={'PAID'}
                          sx={{
                            p: 0,
                          }}
                        >
                          PAID
                        </Option>
                      </Select>
                      <Typography
                        level="body-md"
                        sx={{ color: getStatusTextColor(payout.status) }}
                      >
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      level="body-md"
                      sx={{ color: getStatusTextColor(payout.status) }}
                    >
                      {payout.status}
                    </Typography>
                  )}
                </Box>
                <Divider />
                <Typography level="title-lg" sx={{ color: '#947EFE', mt: 2, mb: 2 }}>
                  {t('details_trans_card')}
                </Typography>
                <Typography level="body-md">
                  {`${t('transaction_card')}: ${payout.pocket_address}`}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default HistoryItemModal;
