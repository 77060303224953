import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';

export const useChartData = () => {
    const { t } = useTranslation();
    const { user } = useSelector(getGlobalUser);

    const seriesActive: number[] = [
        Number(user?.deposit) || 0,
        Number(user?.frozen_limit) || 0,
        Number(user?.banned_frozen_limit) || 0,
    ];

    const seriesInactive: number[] = [
        Number(user?.frozen_limit) || 0,
        Number(user?.deposit) || 0,
        Number(user?.banned_frozen_limit) || 0,
    ];

    const seriesFrozen: number[] = [
        Number(user?.banned_frozen_limit) || 0,
        Number(user?.frozen_limit) || 0,
        Number(user?.deposit) || 0,
    ];

    const optionsActive: ApexCharts.ApexOptions = {
        chart: {
            type: 'donut',
            offsetX: 30,
        },
        colors: ['#947efe', '#343A40', '#343A40'],
        labels: [t('active')],
        stroke: { width: 0, lineCap: 'round' },
        tooltip: { enabled: true },
        legend: {
            show: false,
            labels: {
                colors: ['#CED4DA', '#343A40'],
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {},
                donut: {
                    size: '80%',
                    labels: {
                        show: false,
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
            style: {
                colors: ['#fff'],
            },
        },
        responsive: [
            {
                options: {
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };
    const optionsInactive: ApexCharts.ApexOptions = {
        chart: {
            type: 'donut',
            offsetX: 30,
        },
        colors: ['#495057', '#343A40', '#343A40'],
        labels: [t('active')],
        stroke: { width: 0, lineCap: 'round' },
        tooltip: { enabled: true },
        legend: {
            show: false,
            labels: {
                colors: ['#CED4DA', '#343A40'],
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {},
                donut: {
                    size: '80%',
                    labels: {
                        show: false,
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
            style: {
                colors: ['#fff'],
            },
        },
        responsive: [
            {
                options: {
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    const optionsFrozen: ApexCharts.ApexOptions = {
        chart: {
            type: 'donut',
            offsetX: 30,
        },
        colors: ['#65BEFF', '#343A40', '#343A40'],
        labels: [t('frozen')],
        stroke: { width: 0, lineCap: 'round' },
        tooltip: { enabled: true },
        legend: {
            show: false,
            labels: {
                colors: ['#CED4DA', '#343A40'],
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {},
                donut: {
                    size: '80%',
                    labels: {
                        show: false,
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
            style: {
                colors: ['#fff'],
            },
        },
        responsive: [
            {
                options: {
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    return {
        seriesActive,
        seriesInactive,
        seriesFrozen,
        optionsActive,
        optionsInactive,
        optionsFrozen,
    };
};