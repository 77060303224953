import { Box, Stack, Typography } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useWidth from '../../../hooks/useWidth';
import { formatDateText } from '../../../utils/datePickerFormat';
import { Stats } from '../../../utils/types';

const Profit = ({
  stats,
  filterQueries,
}: {
  stats: Stats | null;
  filterQueries: {
    sDate: string;
    fDate: string;
  };
}) => {
  const { isMobile } = useWidth();
  const { t } = useTranslation();
  const formatAmountNumber = (number: string): string => {
    const formattedNumber = number.replace(',', '.');
    const [integerPart, decimalPart] = formattedNumber.split('.') as [string, string | undefined];
    const spacedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return decimalPart !== undefined ? `${spacedIntegerPart},${decimalPart}` : spacedIntegerPart;
  };

  return (
    <Box
      sx={{
        backgroundColor: '#111317',
        borderRadius: '16px',
        width: isMobile ? '100%' : '480px',
        mt: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 24px',
          backgroundColor: '#18191A',
          borderRadius: '16px 16px 0 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            width:'100%'
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
            <path
              fill="url(#a)"
              d="M14 18.7c-1.3 0-2.4-.5-3.3-1.4-1-1-1.4-2-1.4-3.3 0-1.3.5-2.4 1.4-3.3 1-1 2-1.4 3.3-1.4 1.3 0 2.4.5 3.3 1.4 1 1 1.4 2 1.4 3.3 0 1.3-.5 2.4-1.4 3.3-1 1-2 1.4-3.3 1.4Zm0-2.7c.6 0 1-.2 1.4-.6.4-.4.6-.8.6-1.4 0-.6-.2-1-.6-1.4A2 2 0 0 0 14 12a2 2 0 0 0-1.4.6A2 2 0 0 0 12 14c0 .6.2 1 .6 1.4.4.4.8.6 1.4.6Zm12 14.7c-1.3 0-2.4-.5-3.3-1.4-1-1-1.4-2-1.4-3.3 0-1.3.5-2.4 1.4-3.3 1-1 2-1.4 3.3-1.4 1.3 0 2.4.5 3.3 1.4 1 1 1.4 2 1.4 3.3 0 1.3-.5 2.4-1.4 3.3-1 1-2 1.4-3.3 1.4Zm0-2.7c.6 0 1-.2 1.4-.6.4-.4.6-.8.6-1.4 0-.6-.2-1-.6-1.4A2 2 0 0 0 26 24a2 2 0 0 0-1.4.6A2 2 0 0 0 24 26c0 .6.2 1 .6 1.4.4.4.8.6 1.4.6Zm-14.8 2.7-1.9-1.9L28.8 9.3l1.9 1.9-19.5 19.5Z"
            />
            <defs>
              <linearGradient
                id="a"
                x1="9.6"
                x2="30.7"
                y1="21.6"
                y2="21.6"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#684FDD" />
                <stop offset="1" stopColor="#2F92AC" />
              </linearGradient>
            </defs>
          </svg>
          <Typography
            level="h4"
            sx={{ color: '#F1F1F1', fontSize: '16px', fontWeight: 600 , ml:isMobile? '0':'4px'}}
          >
            {t('dashboard_profit_header')}
          </Typography>
        </Box>
        <Typography
          sx={{ color: '#CED4DA', fontSize: '14px', fontWeight: 400, width: '100%', textAlign: 'end' }}
        >
          {(filterQueries.sDate &&
            filterQueries.fDate &&
            `${formatDateText(filterQueries.sDate)} - ${formatDateText(filterQueries.fDate)}`) ||
            t('search_period')}{' '}
        </Typography>
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography level="title-sm" sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}>
          {t('dashboard_profit_desc')}
        </Typography>
        <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '16px', fontWeight: 600 }}>
          {(stats && stats.totalEarnedAward !== undefined) ? formatAmountNumber(stats.totalEarnedAward.toFixed(2).toString()) : 0} USDT
        </Typography>
      </Stack>
    </Box>
  );
};

export default Profit;
