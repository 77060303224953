import { Box, Divider, Stack, Typography } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useWidth from '../../../hooks/useWidth';
import { Stats } from '../../../utils/types';
import { formatDateText } from '../../../utils/datePickerFormat';
import CircleChart from '../Charts/CircleChart';

const Transactions = ({
  stats,
  filterQueries,
}: {
    stats: Stats | null;
    filterQueries: {
    sDate: string;
    fDate: string;
  };
}) => {
  const { isMobile, isLargeDesktop } = useWidth();
  const { t } = useTranslation();

  const totalSumUah =
    (Number(stats?.acqStatistic.clearSumUAH) || 0) +
    (Number(stats?.statistic.clearSumUAH) || 0);
  const avgOperatorCourse = (Number(stats?.statistic.avgOperatorCourse) || 0);

  const totalSuc =
    (stats?.statistic?.countOfSuccessfulTransactions ?? 0 )+
   (stats?.acqStatistic.countOfSuccessfulTransactionsAcq ?? 0);
  const totalFailed =
    (stats?.statistic?.countOfFailedTransactions ?? 0) +
    (stats?.acqStatistic.countOfFailedTransactionsAcq ?? 0);

  const totalAverageCheck = (totalSumUah !== 0 && totalSuc !== 0) ? totalSumUah / totalSuc : 0;
  const totalTransactions = totalSuc + totalFailed;

  const successfulPercentage = totalTransactions > 0 ? ((totalSuc / totalTransactions) * 100).toFixed(1) + '%' : '0%';
  const failedPercentage = totalTransactions > 0 ? ((totalFailed / totalTransactions) * 100).toFixed(1) + '%' : '0%';
  return (
    <Box
      sx={{ backgroundColor: '#111317', borderRadius: '16px', width:'100%', mt:!isLargeDesktop ? 3 :0}}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 24px',
          backgroundColor: '#18191A',
          borderRadius: '16px 16px 0 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            width:'100%'
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
            <path
              stroke="url(#a)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="5.8"
              strokeWidth="2"
              d="M8 8v21.3a2.7 2.7 0 0 0 2.7 2.7H32"
            />
            <path
              stroke="url(#b)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="5.8"
              strokeWidth="2"
              d="m13.3 22.7 5.4-5.4 5.3 5.4 8-8"
            />
            <path
              stroke="url(#c)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="5.8"
              strokeWidth="2"
              d="M28 14.7h4v4"
            />
            <defs>
              <linearGradient
                id="a"
                x1="8.3"
                x2="32"
                y1="21.8"
                y2="21.8"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#684FDD" />
                <stop offset="1" stopColor="#2F92AC" />
              </linearGradient>
              <linearGradient
                id="b"
                x1="13.6"
                x2="32"
                y1="19.2"
                y2="19.3"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#684FDD" />
                <stop offset="1" stopColor="#2F92AC" />
              </linearGradient>
              <linearGradient
                id="c"
                x1="28.1"
                x2="32"
                y1="17"
                y2="17"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#684FDD" />
                <stop offset="1" stopColor="#2F92AC" />
              </linearGradient>
            </defs>
          </svg>
          <Typography
            level="h4"
            sx={{ Color: '#F1F1F1', fontSize: '16px', fontWeight: 600, ml: isMobile ? '0' : '4px' }}
          >
            {t('dashboard_transactions_header')}
          </Typography>
        </Box>
        <Typography sx={{ Color: '#CED4DA', fontSize: '14px', fontWeight: 400, width: '100%',textAlign:'end' }}>
          {(filterQueries.sDate &&
            filterQueries.fDate &&
            `${formatDateText(filterQueries.sDate)} - ${formatDateText(filterQueries.fDate)}`) ||
            t('search_period')}{' '}
        </Typography>
      </Box>
      <Box
        sx={{
          display: !isLargeDesktop ? 'block' : 'flex',
          p: 3, justifyContent:'space-between'
        }}
      >
        <Box
          sx={{
            display: 'block',
          }}
        >
          <Stack spacing={2}>
            <Typography
              level="title-sm"
              sx={{ color: '#CED4DA', fontSize: isMobile ? '14px' : '16px', fontWeight: 400 }}
            >
              {t('dashboard_transactions_countSec')}
            </Typography>
            <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: isMobile ? '14px' : '16px', fontWeight: 600 }}>
              {totalSuc || '0'}
            </Typography>
          </Stack>
          <Divider />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography
              level="title-sm"
              sx={{ color: '#CED4DA', fontSize: isMobile ? '14px' : '16px', fontWeight: 400 }}
            >
              {t('dashboard_transactions_countFl')}
            </Typography>
            <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: isMobile ? '14px' : '16px', fontWeight: 600 }}>
              {totalFailed || '0'}
            </Typography>
          </Stack>
          <Divider />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography
              level="title-sm"
              sx={{ color: '#CED4DA', fontSize: isMobile ? '14px' : '16px', fontWeight: 400 }}
            >
              {t('dashboard_transactions_average')}
            </Typography>
            <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: isMobile ? '14px' : '16px', fontWeight: 600 }}>
              {totalAverageCheck.toFixed(2) || '0'} &#8372;
            </Typography>
          </Stack>
          <Divider />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography
              level="title-sm"
              sx={{ color: '#CED4DA', fontSize: isMobile ? '14px' : '16px', fontWeight: 400 }}
            >
              {t('averageRate')}
            </Typography>
            <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: isMobile ? '14px' : '16px', fontWeight: 600 }}>
              {avgOperatorCourse.toFixed(2) || '0'}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', ml: isMobile ? 0 : 3, flexDirection: "column", mt: isMobile ? 4 : 0 }}>
          <Box sx={{ position: 'relative', width:'280px'}}>
            <CircleChart data={stats} />
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: isMobile ? '20px' : '32px',
                fontWeight: 600,
                color: '#F1F1F1',
              }}
            >{successfulPercentage}</Typography>
          </Box>

          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  display: 'block',
                  width: '16px',
                  height: '16px',
                  backgroundColor: '#18E806',
                  borderRadius: '50%',
                  marginRight: '8px',
                }}
              ></span>
              <Typography sx={{ fontSize: isMobile ? '14px' : '16px', fontWeight: 500, color: '#F1F1F1', overflowWrap: 'anywhere' }}>
                {t('dashboard_transactionsSec')}{' '}
                <span style={{ color: '#CED4DA' }}>
                  {t('dashboardTransactions')} -
                </span>{' '}
                {successfulPercentage}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  display: 'block',
                  width: '16px',
                  height: '16px',
                  backgroundColor: '#EB5757',
                  borderRadius: '50%',
                  marginRight: '8px',
                }}
              ></span>
              <Typography sx={{ fontSize: isMobile ? '14px' : '16px', fontWeight: 500, color: '#F1F1F1' }}>
                {t('dashboard_transactionsFl')}{' '}
                <span style={{ color: '#CED4DA' }}>
                  {t('dashboardTransactions')} -
                </span>{' '}
                {failedPercentage}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Transactions;
