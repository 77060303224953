import React from "react";
import { Typography, Box, Button, Sheet, Modal, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import IconComponent from '../../components/IconComponent/IconComponent';
import css from './HistoryTrans.module.css';

const SupportModal = (
  {
    openModal,
    toggleOpenModal,
    onSubmit,
    handleChangeSupportRequestReason,
  }: {
    openModal: boolean;
    toggleOpenModal: () => void;
    onSubmit: () => void;
    reason: string;
    handleChangeSupportRequestReason: (value: string) => void;
  }) => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();

  const selectOptions = [
    t('not_our_requisites'),
    t('already_confirmed_in_another_request'),
    t('confirmed_with_amount_error'),
    t('extract_required'),
    t('fake_receipt'),
    t('tech_err_amount_or_currency')
  ];

  return (
    <Modal
      open={openModal}
      onClose={toggleOpenModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'transparent',
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '600px',
          borderRadius: '16px',
          padding: '32px 40px',
          boxShadow: '4px 2px 64px 0 rgba(69, 69, 69, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          border: 'transparent',
        }}
      >
        <IconButton onClick={toggleOpenModal} sx={{ marginLeft: 'auto' }}>
          <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
        </IconButton>
        <Typography
          level="h4"
          sx={{
            textAlign: 'center',
            mb: 3,
            color: '#F1F1F1',
          }}
        >
          {t('select_dispute_support_request')}
        </Typography>
        <form onSubmit={onSubmit}>
          <select
            onChange={(e) => handleChangeSupportRequestReason(e.target.value)}
            id="request"
            name="request"
            autoFocus
            className={css.select}
          >
            <option value="">Select an option</option>
            {selectOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>

          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              type="submit"
              sx={{
                width: isMobile ? '100%' : '300px',
                background: 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                color: '#FFF',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  color: '#f1f1f1',
                },
              }}
            >
              {t('support_btn_send')}
            </Button>
          </Box>
        </form>
      </Sheet>
    </Modal>
  )
}

export default SupportModal;