import { Box } from '@mui/joy';
import React from 'react';

import { Payout } from '../../../utils/types';
import SearchResultItem from './SearchResultItem';

const SearchResultList = ({ list }: { list: Payout[] }) => {

  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      {list.map((listItem: Payout) => (
        <SearchResultItem listItem={listItem} key={listItem.id} />
      ))}
    </Box>
  );
};

export default SearchResultList;
