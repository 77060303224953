import { Button, Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { Payout } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import HistoryItemModal from './HistoryItemModal';
import { maskCreditCardNumber } from '../../utils/formatCreditCard';
import { formatDateTime } from '../../utils/dateFormater';
import { getStatusTextColor } from '../../utils/getStatusColor';

const PayoutById = ({ payoutById }: { payoutById: Payout }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setOpen(inOpen);
    };
    return (
        <>
            <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',

                }}
            >
                <thead>
                <tr>
                    <th style={{ color: '#F1F1F1' }}>{t('history_table_card')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('history_table_priceUah', {
                        currency: (localStorage.getItem('base_currency')) || '',
                    })}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('history_table_priceUsdt')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('history_table_startDate')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('history_table_endDate')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('history_table_status')}</th>
                    <th style={{ color: '#F1F1F1' }}>{t('history_table_actions')}</th>
                </tr>
                </thead>
                <tbody>
                <tr key={payoutById.id}>
                    <td>
                        <Typography level="body-xs">
                            {maskCreditCardNumber(payoutById.pocket_address)}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-xs">
                            {(Number(payoutById.amount).toFixed(2)) || t('not_found')}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-xs">
                            {(Number(payoutById.usdt_amount).toFixed(2)) || t('not_found')}
                        </Typography>
                    </td>
                    <td>
                        <Typography level="body-md">{formatDateTime(payoutById.created_at.toString())}</Typography>
                    </td>
                    <td>
                        <Typography level="body-md">{formatDateTime(payoutById.updated_at.toString())}</Typography>
                    </td>
                    <td>
                        <Typography level="body-md" sx={{ color: getStatusTextColor(payoutById?.status) }}>
                            {payoutById?.status &&
                              payoutById.status.charAt(0).toUpperCase() +
                              payoutById.status.slice(1).toLowerCase()}
                        </Typography>
                    </td>
                    <td>
                        <Button
                          variant="plain"
                          sx={{
                              display: 'block',
                              m: 0,
                              borderBottom: '1px solid #947EFE',
                              color: '#947EFE',
                              borderRadius: 0,
                              p: 0,
                          }}
                          onClick={toggleDrawer(true)}
                        >
                            {t('history_table_details')}
                        </Button>
                    </td>
                </tr>
                <HistoryItemModal
                  id={payoutById.id}
                  open={open}
                  onClose={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                />

                </tbody>
            </Table>
        </>
    );
};

export default PayoutById;