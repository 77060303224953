import { Box, Switch, switchClasses, Typography, Button } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { Banks, Cards, Tags } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import EditCardModal from './EditCardModal';
import DeleteCardModal from './DeleteCardModal';
import { formatingCardNumber } from '../../utils/formationCardNum';

const CardItem = ({
  card,
  banks,
  tagList,
  handleDelete,
  handleUpdate,
  handleEditCard,
}: {
  card: Cards;
  banks: Banks[];
  tagList: Tags[];
  handleUpdate: ({
    id,
    balance,
    accessibility,
  }: {
    id: number;
    balance: number;
    accessibility: boolean;
  }) => void;
  handleDelete: (id: number) => void;
  selected: readonly number[];
  setSelected: React.Dispatch<React.SetStateAction<readonly number[]>>;
  handleEditCard: (
    cards: {
      id: number;
      bank_id: number;
      tag: number | null;
      monthly_limit: number;
      dailyLimit: number;
      label: string;
      is_active: boolean;
      send_id: string;
      daily_transaction_limit: number;
      allow_same_amount: boolean;
    }[]
  ) => void;
}) => {
  const { t } = useTranslation();

  const [accessibility, setAccessibility] = useState(card.is_active);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    setAccessibility(card.is_active);
  }, [card]);

  const handleChangeCheckbox = async () => {
    handleUpdate({
      id: card.id,
      balance: card.balance,
      accessibility: !accessibility,
    });
    setAccessibility(!accessibility);
  };
  const handleDeleteCard = async () => handleDelete(card.id);

  return (
    <>
      <tr>
        <td>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box sx={{ display: 'flex', gap: '8px', flexDirection:'row' }}>
              <Typography level="body-sm" sx={{ fontWeight: 400, maxWidth:'135px', display: 'flex', justifyContent:'center', alignItems:'center' }}>
                {(card.label && card.label) || t('not_found')}
              </Typography>
            </Box>
          </Box>
        </td>
        <td>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Box sx={{ display: 'flex', gap: '8px', flexDirection:'row' }}>
              <Box sx={{ display: 'flex', flexDirection:'column' }}>

              <Typography
                level="body-sm"
                sx={{ color: '#F1F1F1', overflowWrap: 'anywhere', minWidth:'max-content'}}
              >
                {formatingCardNumber(card.pocket_address)}
              </Typography>
                <Typography
                  level="body-sm"
                  sx={{ color: '#F1F1F1', overflowWrap: 'anywhere', minWidth:'75px'}}
                >
                  {card.bank.bank.title}
                </Typography>
              </Box>
            </Box>
          </Box>
        </td>
        <td colSpan={2}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography level="body-sm" sx={{ color: '#CED4DA', minWidth:'max-content' }}>
              {t('card_table_turnoverDay', {
                daily: (card.daily_balance && card.daily_balance.toFixed(2)) || 0,
                currency: (localStorage.getItem('base_currency')) || '',
              })}
            </Typography>

            <Typography level="body-sm" sx={{ color: '#CED4DA', minWidth:'max-content' }}>
              {t('card_table_turnoverMonth', {
                monthly: (card.month_balance && card.month_balance.toFixed(2)) || 0,
                currency: (localStorage.getItem('base_currency')) || 0,
              })}
            </Typography>
          </Box>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1', }}>
            {`${card.daily_limit} ${localStorage.getItem('base_currency')}`}
          </Typography>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1', }}>
            {`${card.month_limit} ${localStorage.getItem('base_currency')}`}
          </Typography>
        </td>
        <td>
          <Typography level="body-sm" sx={{ color: '#F1F1F1' }}>
            {card.daily_transaction_done} / {card.daily_transaction_limit}
          </Typography>
        </td>
        <td style={{ color: '#F1F1F1', fontSize: '16px' }}>{card.bank.bank.title}</td>
        {tagList.length !==0 && (
          <td>
            <Typography
              level="body-sm"
              sx={{
                bgcolor: (card.tag && 'rgba(104, 79, 221, 0.2)') || 'transparent',
                width: 'fit-content',
                p: '2px 10px',
                borderRadius: '8px',
                color: '#F1F1F1',

              }}
            >
              {(card.tag && `#${card.tag.title}`) || t('not_found')}
            </Typography>
          </td>
        )}
        <td>
          {!card.archivated && (
            <Switch
              size="md"
              variant={'solid'}
              checked={accessibility}
              onChange={handleChangeCheckbox}
              sx={() => ({
                '--Switch-thumbSize': '20px',
                '--Switch-trackWidth': '43px',
                '--Switch-trackHeight': '27px',
                '--Switch-trackBackground': 'rgb(169,42,32)',
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': 'rgb(169,42,32)',
                },
                '&:active': {
                  '--Switch-thumbWidth': '25px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': 'rgb(32,182,44)',
                  '&:hover': {
                    '--Switch-trackBackground': 'rgb(32,182,44)',
                  },
                },
              })}
            />
          )}
        </td>
        <td onMouseOver={() => setShowButtons(true)} onMouseOut={() => setShowButtons(false)}>
          {!showButtons && (
            <Button
              variant="plain"
              sx={{
                display: 'block',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
                <path
                  fill="#947EFE"
                  d="m19 20.25c0-.402-.356-.75-.75-.75-2.561 0-11.939 0-14.5 0-.394 0-.75.348-.75.75s.356.75.75.75h14.5c.394 0 .75-.348.75-.75zm-7.403-3.398 9.124-9.125c.171-.171.279-.423.279-.684 0-.229-.083-.466-.28-.662l-3.115-3.104c-.185-.185-.429-.277-.672-.277s-.486.092-.672.277l-9.143 9.103c-.569 1.763-1.555 4.823-1.626 5.081-.02.075-.029.15-.029.224 0 .461.349.848.765.848.511 0 .991-.189 5.369-1.681zm-3.27-3.342 2.137 2.137-3.168 1.046zm.955-1.166 7.651-7.616 2.335 2.327-7.637 7.638z"                />
              </svg>
            </Button>
          )}

          {showButtons && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                className={'btnGradient'}
                sx={{
                  p: '10px 0',
                  width: '80px',
                  fontSize: '14px',
                  color: '#f1f1f1',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
                onClick={() => setEditOpen(!editOpen)}
              >
                {t('cardEditBtn')}
              </Button>
              <Button
                variant="outlined"
                className={'btnGradient'}
                sx={{
                  ml: 1,
                  p: '10px 0',
                  width: '80px',
                  fontSize: '14px',
                  color: '#f1f1f1',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
                onClick={() => setDeleteOpen(!deleteOpen)}
              >
                {t('cardDeleteBtn')}
              </Button>
            </Box>
          )}
        </td>
      </tr>
      <EditCardModal
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        card={card}
        handleEditCard={handleEditCard}
        banks={banks}
        tagList={tagList}
      />
      <DeleteCardModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        handleDeleteCard={handleDeleteCard}
        textBtn={t('card_modal_deleteBtn')}
        title={t('card_modal_deleteHeader')}
      />
    </>
  );
};
export default CardItem;
