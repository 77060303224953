import { Box, IconButton, Typography } from '@mui/joy';
import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import useWidth from '../../hooks/useWidth';
import { toggleSidebar } from '../../utils/closeSidebar';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import ToggleLanguage from '../ToggleLanguage/ToggleLanguage';
import { useTranslation } from 'react-i18next';
import Course from '../Course';
import Logo from '../Logo/Logo';
const Header = ({ showLogo }: { showLogo: boolean }) => {
  const { isMobile } = useWidth();
  const { t } = useTranslation();
  const { isLargeDesktop } = useWidth();
  const { user, isLoggedIn } = useSelector(getGlobalUser);

  return (
    <Box
      component="header"
      sx={{
        width: '100%',
        borderBottom: '1px solid var(--joy-palette-divider)',
        padding: isMobile ? '10px 0 0 0' : '10px 20px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        {!isLargeDesktop && (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <IconButton
              onClick={() => toggleSidebar()}
              variant="outlined"
              color="neutral"
              size="sm"
            >
              <GiHamburgerMenu />
            </IconButton>
            <ToggleLanguage />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', }}>{(showLogo && isLargeDesktop) && <Logo />}</Box>

        {isLoggedIn && !isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center', }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {user?.acquiring_tax !== undefined && (
                <>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <Typography
                    level="body-md"
                    sx={{ fontSize: '14px', color: '#868E96', fontWeight: 500, marginRight: '4px' }}
                  >
                    {t('details_trans_tax') + ' Qac:'}
                  </Typography>
                  <Typography
                    level="body-md"
                    sx={{ fontSize: '14px', color: '#F1F1F1', fontWeight: 500, marginRight: '40px' }}
                  >
                    {user.acquiring_tax}%
                  </Typography>
                </Box>
                </>

              )}
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography
                  level="body-md"
                  sx={{ fontSize: '14px', color: '#868E96', fontWeight: 500, marginRight: '4px' }}
                >
                  {t('details_trans_tax') + ' P2P:'}
                </Typography>
                <Typography
                  level="body-md"
                  sx={{ fontSize: '14px', color: '#F1F1F1', fontWeight: 500, marginRight: '40px' }}
                >
                  {user.tax}%
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

              <Typography level="body-md"
                sx={{ fontSize: '14px', color: '#868E96', fontWeight: 500, marginRight: '4px' }}
              >
                {t('history_table_course')}:
              </Typography>
              <Course />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginRight: '40px', alignItems: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M 7.5 0.355469 C 9.570312 0.355469 11.25 1.957031 11.25 3.929688 C 11.25 5.902344 9.570312 7.5 7.5 7.5 C 5.429688 7.5 3.75 5.902344 3.75 3.929688 C 3.75 1.957031 5.429688 0.355469 7.5 0.355469 Z M 7.5 9.285156 C 11.625 9.285156 15 10.855469 15 12.855469 L 15 14.644531 L 0 14.644531 L 0 12.855469 C 0 10.855469 3.375 9.285156 7.5 9.285156 Z M 7.5 9.285156 "
                  />
                </svg>
                <Typography level="body-md" sx={{ fontSize: '14px', ml: 1, color: '#F1F1F1', fontWeight: 500, minWidth:'max-content'}}>
                  {user.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {isLargeDesktop && <ToggleLanguage />}
      </Box>
      {isLoggedIn && isMobile && (
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            mb: 1,
            mt: 2
          }}
        >
          <Box sx={{ display: 'block' }}>
            <Typography level="body-sm" sx={{ color: '#F1F1F1', fontSize: '14px' }}>
              {t('details_trans_tax')} P2P: {user.tax}%
            </Typography>
            <Typography level="body-sm" sx={{ color: '#F1F1F1', fontSize: '14px' }}>
              {t('details_trans_tax')} QAC: {user.acquiring_tax}%
            </Typography></Box>
          <Course />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
              <path
                fill="#ADB5BD"
                d="M10 .5a5 5 0 1 1 0 10 5 5 0 0 1 0-10ZM10 13c5.5 0 10 2.2 10 5v2.5H0V18c0-2.8 4.5-5 10-5Z"
              />
            </svg>
            <Typography level="title-sm" sx={{ ml: 1, color: '#F1F1F1' }}>
              {user.name}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default Header;
