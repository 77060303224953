import {
  Box,
  Typography,
  Sheet,
  Input,
  FormControl,
  Select,
  Option,
  Button,
  Modal,
  IconButton,
} from '@mui/joy';
import { DateRange, RangeKeyDict } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HistoryTrans from '../../components/HistoryTransactions';
import { getGlobalUser } from '../../redux/auth/selectors';

import ReactPaginate from 'react-paginate';

import { useTranslation } from 'react-i18next';
import { useHistory } from '../../hooks/useHistory';
import { status } from '../../db/allDbs';
import { formatDateText } from '../../utils/datePickerFormat';
import useWidth from '../../hooks/useWidth';
import HistoryList from '../../components/HistoryTransactions/MobileList/HistoryList';
import css from './History.module.css';
import {
  downloadHistory,
  findTransactionByCustomerId,
  findTransactionByCustomerName,
  findTransactionById,
} from '../../services/operators';
import { toast } from 'react-toastify';
import TransactionById from '../../components/HistoryTransactions/TransactionById';
import TransactionByIdMob from '../../components/HistoryTransactions/MobileList/TransactionByIdMob';
import { itemType } from '../../utils/types';
export type ItemRange = {
  selection: itemType;
};

export const initialFilterState = {
  searchQuery: '',
  searchBank: null,
  searchStatus: null,
  searchByPocket: null,
  searchByChangedBy: null,
  searchByType: null,
  sDate: null,
  fDate: null,
  sort: null,
};

const History = () => {
  const { t } = useTranslation();
  const { isMobile, isLargeDesktop } = useWidth();
  const { user, access_token } = useSelector(getGlobalUser);
  const [filterQueries, setFilterQueries] = useState<{
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    searchByPocket: string | null;
    searchByChangedBy: string | null;
    searchByType: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  }>(initialFilterState);
  const { pageCount, handlePageClick, banks, notifications } = useHistory({
    userId: user.id,
    filterQueries,
  });

  const [transactions, setTransactions] = useState(notifications);

  useEffect(() => {
    setTransactions(notifications);
  }, [notifications]);


  const [open, setOpen] = useState(false);
  const [datePickerState, setDatePickerState] = useState<
    {
      startDate: Date;
      endDate: Date;
      key: string;
    }[]
  >([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [transactionById, setTransactionById] = useState(null)
  const [searchValue, setSearchValue] = useState('');

  const handleRestSearch = () => {
    setFilterQueries(initialFilterState);
    setTransactionById(null);
    setSearchValue('');
  };

  const toggleOpenModal = () => {
    setOpen(!open);
  };

  const handleChangeBank = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setFilterQueries(prev => {
      return { ...prev, searchBank: value };
    });
  };

  const handleChangeSort = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setFilterQueries(prev => {
      return { ...prev, sort: value };
    });
  };

  const handleChangeStatus = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setFilterQueries(prev => {
      return { ...prev, searchStatus: value };
    });
  };

  const handleType = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setFilterQueries(prev => {
      return { ...prev, searchByType: value };
    });
  };

  const handleChangeDate = (item: ItemRange) => {
    setDatePickerState([item.selection]);
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (!value) {
      setTransactionById(null);
    }
  };

  const onSearchBntClick = async () => {
    try {
      const matchesHashedId = searchValue.match(/[^\s]+=/g);
      if (matchesHashedId) {
        const id = matchesHashedId.reduce((longest: any, current: any) =>
          current.length > longest.length ? current.trim() : longest.trim()
        );

        if (id) {
          const transaction = await findTransactionById(user.id, searchValue);
          if (transaction) {
            setTransactionById(transaction[0]);
            return;
          }
        }
      }

      const searchByCustomerIdResult = await findTransactionByCustomerId(user.id, searchValue);
      if (searchByCustomerIdResult.transactions && searchByCustomerIdResult.transactions.length > 0) {
        setTransactions(searchByCustomerIdResult.transactions);
        return;
      }

      const searchByName = await findTransactionByCustomerName(user.id, searchValue);
      if (searchByName.transactions && searchByName.transactions.length > 0) {
        setTransactions(searchByName.transactions);
        return;
      } else {
        toast.info(t("not_found"));
      }

    } catch (error) {
      console.log(error);
    }
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchBntClick();
    }
  };

  const handleAddDeadline = async () => {
    setFilterQueries(prev => {
      return {
        ...prev,
        sDate: datePickerState[0].startDate,
        fDate: datePickerState[0].endDate,
      };
    });
    setOpen(!open);
  };

  const handleDownload = async () => {
    try {
      await downloadHistory(filterQueries, access_token);
    } catch (error) {
      console.error('Error while downloading history', error);
    }
  };

  return (
    <Box sx={{ mb: 3, pl: isMobile ? 0 : 3, width: '100%', }}>
      <Typography level="h2" sx={{ p: '20px 0 ', fontWeight: 600, fontSize: '20px' }}>
        {t('history_header')}
      </Typography>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          gap: 1.5,
        }}
      >
        <FormControl sx={{ flex: 1, mb: 3, position: 'relative' }} size="sm">
          <Input
            sx={{
              padding: '10px 10px 10px 50px',
              width: !isMobile ? '350px':'100%',
              boxSizing: 'border-box',
              borderRadius: '8px',
              border: '0.2px solid #9fa6ad',
              fontSize: '14px',
              maxHeight: '40px',
              color: '#868E96',
              '--Input-placeholderOpacity': 1,
              '--Input-focusedInset': 'transparent',
            }}
            value={searchValue}
            placeholder={t('history_search_placeholder')}
            onChange={(e: { target: { value: string } }) => {
              handleSearchChange(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
          <IconButton variant='plain' sx={{
            position: 'absolute', p: 0, top: '50%',
            left: '12px',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer', '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
            onClick={() => onSearchBntClick()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" fill="none"><path stroke="#F1F1F1" strokeLinecap="round" strokeLinejoin="round" d="M11 18.2a8 8 0 0 0 8-8.1 8 8 0 0 0-8-8.2 8 8 0 0 0-8 8.2 8 8 0 0 0 8 8.1Z" /><path stroke="#F1F1F1" strokeLinecap="round" d="m18 18 4 4" /></svg>
          </IconButton>
        </FormControl>
        <Box
          sx={{
            display: (!isMobile && 'flex') || 'block',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: (isLargeDesktop && 'flex') || 'block', mb:2, flexWrap: 'wrap', gap:'16px'}}>
            <Select
              placeholder={t('card_search_bank')}
              variant="outlined"
              slotProps={{
                listbox: {
                  sx: {
                    '--List-padding': '0px',
                    '--ListItem-radius': '0px',
                  },
                },
              }}
              indicator={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M 2.773438 5.617188 C 2.597656 5.390625 2.300781 5.285156 2.015625 5.34375 C 1.730469 5.40625 1.503906 5.621094 1.441406 5.894531 C 1.378906 6.171875 1.488281 6.453125 1.726562 6.625 L 8.476562 12.742188 C 8.765625 13.019531 9.234375 13.019531 9.523438 12.742188 L 16.273438 6.625 C 16.511719 6.453125 16.621094 6.171875 16.558594 5.894531 C 16.496094 5.621094 16.269531 5.40625 15.984375 5.34375 C 15.699219 5.285156 15.402344 5.390625 15.226562 5.617188 L 9 11.230469 Z M 2.773438 5.617188 "                  />
                </svg>
              }
              onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
                handleChangeBank(_event, newValue);
              }}
              value={filterQueries.searchBank}
              sx={{
                color: '#CED4DA',
                '--Input-radius': '8px',
                '--Select-placeholderOpacity': 1,
                mb: (!isLargeDesktop && 2) || 0,
                fontSize:'14px',
              }}
            >
              <Option
                key={'all'}
                value={'all'}
                sx={{
                  p: 0
                }}
              >
                <Typography sx={{
                  width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                  },
                }}>
                  {t('filter_All')}
                </Typography>
              </Option>
              {banks &&
                banks.map(({ title, id }) => (
                  <Option
                    key={id}
                    value={title}
                    sx={{
                      p: 0,
                      '&[aria-selected="true"]': {
                        backgroundColor: '#684FDD',
                        color: '#F1F1F1',
                      },
                    }}
                  >
                    <Typography sx={{
                      width: '100%', height: '100%', p: 1,
                       color: '#F1F1F1',
                      '&:hover, &:focus': {
                        backgroundColor: '#684FDD',
                      },
                    }}>
                      {title}
                    </Typography>
                  </Option>
                ))}
            </Select>

            <Select
              placeholder={t('search_status')}
              variant="outlined"
              slotProps={{
                listbox: {
                  sx: {
                    '--List-padding': '0px',
                    '--ListItem-radius': '0px',
                  },
                },
              }}
              indicator={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M 2.773438 5.617188 C 2.597656 5.390625 2.300781 5.285156 2.015625 5.34375 C 1.730469 5.40625 1.503906 5.621094 1.441406 5.894531 C 1.378906 6.171875 1.488281 6.453125 1.726562 6.625 L 8.476562 12.742188 C 8.765625 13.019531 9.234375 13.019531 9.523438 12.742188 L 16.273438 6.625 C 16.511719 6.453125 16.621094 6.171875 16.558594 5.894531 C 16.496094 5.621094 16.269531 5.40625 15.984375 5.34375 C 15.699219 5.285156 15.402344 5.390625 15.226562 5.617188 L 9 11.230469 Z M 2.773438 5.617188 "                  />
                </svg>
              }
              onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
                handleChangeStatus(_event, newValue);
              }}
              value={filterQueries.searchStatus}
              sx={{
                color: '#CED4DA',
                p: 1,
                '--Input-radius': '8px',
                '--Select-placeholderOpacity': 1,
                mb: (!isLargeDesktop && 2) || 0,
                fontSize:'14px',
              }}
            >
              <Option
                key={'all'}
                value={'all'}
                sx={{
                  p: 0
                }}
              >
                <Typography sx={{
                  width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                  },
                }}>
                  {t('filter_All')}
                </Typography>

              </Option>
              {status &&
                status.map(({ title, id }) => (
                  <Option
                    key={id}
                    value={title}
                    sx={{
                      p: 0,
                      '&[aria-selected="true"]': {
                        backgroundColor: '#684FDD',
                        color: '#F1F1F1',
                      },
                    }}
                  >
                    <Typography sx={{
                      width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                      '&:hover, &:focus': {
                        backgroundColor: '#684FDD',
                       
                      },
                    }}>
                      {title}
                    </Typography>
                  </Option>
                ))}
            </Select>

            <Select
              placeholder={t('history_table_laber')}
              variant="outlined"
              slotProps={{
                listbox: {
                  sx: {
                    '--List-padding': '0px',
                    '--ListItem-radius': '0px',
                  },
                },
              }}
              indicator={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M 2.773438 5.617188 C 2.597656 5.390625 2.300781 5.285156 2.015625 5.34375 C 1.730469 5.40625 1.503906 5.621094 1.441406 5.894531 C 1.378906 6.171875 1.488281 6.453125 1.726562 6.625 L 8.476562 12.742188 C 8.765625 13.019531 9.234375 13.019531 9.523438 12.742188 L 16.273438 6.625 C 16.511719 6.453125 16.621094 6.171875 16.558594 5.894531 C 16.496094 5.621094 16.269531 5.40625 15.984375 5.34375 C 15.699219 5.285156 15.402344 5.390625 15.226562 5.617188 L 9 11.230469 Z M 2.773438 5.617188 "                  />
                </svg>
              }
              onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
                handleChangeSort(_event, newValue);
              }}
              value={filterQueries.sort}
              sx={{
                color: '#CED4DA',
                p: 1,
                '--Input-radius': '8px',
                '--Select-placeholderOpacity': 1,
                mb: (!isLargeDesktop && 2) || 0,
                fontSize:'14px',
              }}
            >
              <Option key={'all'} value={'all'} sx={{ p: 0 }}>
                <Typography sx={{
                  width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                  },
                }}>
                  {t('filter_All')}
                </Typography>

              </Option>
              <Option
                value={'a-b'}
                variant="plain"
                sx={{
                  p: 0,
                  '&[aria-selected="true"]': {
                    backgroundColor: '#684FDD',
                    color: '#F1F1F1',
                  },
                }}
              >
                <Typography sx={{
                  width: '100%', height: '100%', p: 1,
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                    color: '#F1F1F1',
                  },
                }}>
                  {t('history_table_minPrice', { currency: localStorage.getItem('base_currency')})}
                </Typography>
              </Option>
              <Option
                value={'a+b'}
                variant="plain"
                sx={{
                  p: 0,
                  '&[aria-selected="true"]': {
                    backgroundColor: '#684FDD',
                    color: '#F1F1F1',
                  },
                }}
              >
                <Typography sx={{
                  width: '100%', height: '100%', p: 1,
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                    color: '#F1F1F1',
                  },
                }}>
                  {t('history_table_maxPrice')}
                </Typography>
              </Option>
            </Select>
            <Select
              placeholder={t('history_table_type')}
              variant="outlined"
              slotProps={{
                listbox: {
                  sx: {
                    '--List-padding': '0px',
                    '--ListItem-radius': '0px',
                  },
                },
              }}
              indicator={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M 2.773438 5.617188 C 2.597656 5.390625 2.300781 5.285156 2.015625 5.34375 C 1.730469 5.40625 1.503906 5.621094 1.441406 5.894531 C 1.378906 6.171875 1.488281 6.453125 1.726562 6.625 L 8.476562 12.742188 C 8.765625 13.019531 9.234375 13.019531 9.523438 12.742188 L 16.273438 6.625 C 16.511719 6.453125 16.621094 6.171875 16.558594 5.894531 C 16.496094 5.621094 16.269531 5.40625 15.984375 5.34375 C 15.699219 5.285156 15.402344 5.390625 15.226562 5.617188 L 9 11.230469 Z M 2.773438 5.617188 "                  />
                </svg>
              }
              onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
                handleType(_event, newValue);
              }}
              value={filterQueries.searchByType}
              sx={{
                color: '#CED4DA',
                p: 1,
                '--Input-radius': '8px',
                '--Select-placeholderOpacity': 1,
                mb: (!isLargeDesktop && 2) || 0,
                fontSize:'14px',
              }}
            >
              <Option
                key={'all'}
                value={'all'}
                sx={{
                  p: 0
                }}
              >
                <Typography sx={{
                  width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                  },
                }}>
                  {t('filter_All')}
                </Typography>
              </Option>
              <Option
                key={'p2p'}
                value={'p2p'}
                sx={{
                  p: 0,
                  '&[aria-selected="true"]': {
                    backgroundColor: '#684FDD',
                    color: '#F1F1F1',
                  },
                }}
              >
                <Typography sx={{
                  width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                  },
                }}>
                  P2P
                </Typography>
              </Option>
              <Option
                key={'iban'}
                value={'iban'}
                sx={{
                  p: 0,
                  '&[aria-selected="true"]': {
                    backgroundColor: '#684FDD',
                    color: '#F1F1F1',
                  },
                }}
              >
                <Typography sx={{
                  width: '100%', height: '100%', p: 1, color: '#F1F1F1',
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                  },
                }}>
                  IBAN
                </Typography>
              </Option>
            </Select>

            <Button
              variant="outlined"
              color="neutral"
              onClick={toggleOpenModal}
              startDecorator={
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none">
                  <path
                    fill="url(#a)"
                    d="M18 9.5v7a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-7h18Zm-5-9a1 1 0 0 1 1 1v1h2a2 2 0 0 1 2 2v3H0v-3a2 2 0 0 1 2-2h2v-1a1 1 0 0 1 2 0v1h6v-1a1 1 0 0 1 1-1Z"
                  />
                  <defs>
                    <linearGradient
                      id="a"
                      x1=".2"
                      x2="18"
                      y1="10.8"
                      y2="10.8"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#684FDD" />
                      <stop offset="1" stopColor="#2F92AC" />
                    </linearGradient>
                  </defs>
                </svg>
              }
            >
              {(filterQueries.sDate &&
                filterQueries.fDate &&
                `${formatDateText(filterQueries.sDate)} - ${formatDateText(
                  filterQueries.fDate
                )}`) ||
                t('search_period')}
            </Button>
           
          </Box>
          <Box sx={{ display: (isLargeDesktop && 'flex') || 'block', mb:2 }}>
            <Button className={css.btnGradient} sx={{
              color: '#f1f1f1',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
              variant="outlined"
              type="button"
              onClick={(e: SyntheticEvent) => {
                e.preventDefault();
                handleDownload();
              }}>
              {t('transactionBtn')}
            </Button>
            <Button
              variant="outlined"
              className={css.btnGradient}
              onClick={handleRestSearch}
              sx={{
                ml: 2,
                color: '#f1f1f1',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                },
              }}
            >
              {t('resetFilters')}
            </Button>
         </Box>
         
        </Box>
        {(!transactionById && isMobile) && (<HistoryList pageCount={pageCount} handlePageClick={handlePageClick} list={transactions} />)}
        {(transactionById && isMobile) && (<TransactionByIdMob transactionById={transactionById} />)}
        {(transactionById && !isMobile) && (<TransactionById transactionById={transactionById} />)}
        {(!transactionById && !isMobile) && (
          <>
            <HistoryTrans notifications={transactions} />
            {pageCount > 1 && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  mt: 2,
                }}
              >
                <ReactPaginate
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  previousLabel={t('pagination_prev')}
                  nextLabel={t('pagination_next')}
                  containerClassName={'pagination'}
                  pageLinkClassName={'page-number'}
                  previousLinkClassName={'page-number'}
                  nextLinkClassName={'page-number'}
                  activeLinkClassName={'active'}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={1}
                />
              </Box>
            )}
          </>)}

      </Box>

      <Modal
        open={open}
        onClose={() => {
          setOpen(!open);
        }}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: 'md',
            p: 4,
            boxShadow: 'lg',
          }}
        >
          <Typography level="title-lg" textAlign={'center'} sx={{ mb: 2 }}>
            {t('search_period')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(rangesByKey: RangeKeyDict) => handleChangeDate(rangesByKey as ItemRange)}
              moveRangeOnFirstSelection={false}
              ranges={datePickerState}
              maxDate={new Date()}
            />
          </Box>
          <Button
            sx={{
              display: 'block',
              m: '20px auto 0 auto',
              borderRadius: '8px',
              p: '6px 44px',
              background:
                'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
            onClick={handleAddDeadline}
          >
            {t('settings_save_btn')}
          </Button>
        </Sheet>
      </Modal>
    </Box>
  );
};

export default History;
