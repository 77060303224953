import { Box, Button, Typography } from '@mui/joy';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../utils/dateFormater';
import { maskCreditCardNumber } from '../../utils/formatCreditCard';
import { getStatusTextColor } from '../../utils/getStatusColor';
import { Transactions } from '../../utils/types';
import HistoryItemModal from './HistoryItemModal';

const HistoryItem = ({
  notification,
}:
  {
    notification: Transactions;
    selected: readonly number[];
    setSelected: Dispatch<SetStateAction<readonly number[]>>;
  }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };

  const formatAmountNumber = (number: string): string => {
    const formattedNumber = number.replace(',', '.');
    const [integerPart, decimalPart] = formattedNumber.split('.') as [string, string | undefined];
    const spacedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return decimalPart !== undefined ? `${spacedIntegerPart},${decimalPart}` : spacedIntegerPart;
  };
  return (
    <>
      <tr key={notification.id}>
        <td>
          <Typography level="body-xs">
            {(notification.label && notification.label) || t('not_found')}
          </Typography>
        </td>
        <td>
          <Typography level="body-xs">
            {maskCreditCardNumber(notification.pocket_address)}
          </Typography>
          <Typography level="title-sm" sx={{ overflowWrap: 'anywhere' }}>{notification.operator_bank_title}</Typography>
        </td>
        <td>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography level="body-md" sx={{ fontWeight: 700, overflowWrap: 'anywhere' }}>
              {formatAmountNumber(Number(notification.confirmed_amount_uah).toFixed(2).toString())} {notification.currency}
            </Typography>
          </Box>
        </td>
        <td>
          <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>
            {Number(notification.operator_amount_usdt).toFixed(2)}
          </Typography>
        </td>
        <td colSpan={2}>
          <Box
            sx={{
              display: 'flex',
              flexWrap:'wrap',
              gap: '5px',
            }}
          >
            <Typography level="body-md"
                        sx={{ fontSize: '12px', overflowWrap: 'anywhere', minWidth:'max-content' }}>{t('history_table_startDate')}:</Typography>
            <Typography level="body-md"
                        sx={{ fontSize: '12px', overflowWrap: 'anywhere' }}>{formatDateTime(notification.createdAt)}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap:'wrap',
              gap: '5px',
            }}
          >
            <Typography level="body-md"
                        sx={{ fontSize: '12px', overflowWrap: 'anywhere', minWidth:'max-content'}}>{t('history_table_endDate')}:</Typography>
            <Typography level="body-md"
                        sx={{ fontSize: '12px', overflowWrap: 'anywhere' }}>{formatDateTime(notification.updatedAt)}</Typography>
          </Box>
        </td>
        <td>
          <Typography level="body-md" sx={{ color: getStatusTextColor(notification?.status) }}>
            {notification?.status &&
              notification.status.charAt(0).toUpperCase() +
              notification.status.slice(1).toLowerCase()}
          </Typography>
        </td>
        <td>
          <Button
            variant="plain"
            sx={{
              display: 'block',
              m: 0,
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </td>
      </tr>

      <HistoryItemModal
        id={notification.id}
        open={open}
        onClose={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </>
  );
};
export default HistoryItem;
