import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import ActiveTrans from '../../components/ActivePayouts';
import ActiveList from '../../components/ActivePayouts/MobileList/ActiveList';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import { getAllPayouts, getPayoutByHashedId, savePayoutBanks } from '../../services/operators';
import { initialFilterState } from '../History/History';
import PendingPayout from '../../components/ActivePayouts/PendingPayout';
import PendingList from '../../components/ActivePayouts/MobileList/PendingList';
import { Payout } from '../../utils/types';
import { Button, FormControl, IconButton, Input } from '@mui/joy';
import { toast } from 'react-toastify';
import SearchResultPayouts from '../../components/ActivePayouts/SearchResultPayouts';
import SearchResultList from '../../components/ActivePayouts/MobileList/SearchResultList';

const ActivePayouts = () => {
  const { isMobile } = useWidth();
  const { t } = useTranslation();
  const { activePayouts, user } = useSelector(getGlobalUser);
  const [activePayoutItems, setActivePayoutItems] = useState<Payout[]>([]);
  const [pendingPayouts, setPendingPayouts] = useState([]);
  const [capacity, setCapacity] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [transactionById, setTransactionById] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    user.payout_banks || []
  );
  useEffect(() => {
    const fetch = async () => {
      const data = await getAllPayouts(user.id as number, null, null, {
        ...initialFilterState,
        searchStatus: 'PROCESSING',
      });

      if (data) {
        const items = data.payouts.reverse();
        setActivePayoutItems(items);
      }
    };
    fetch();
  }, [activePayouts]);
  //const course = +user.payout_course - (+user.payout_course * +user.payout_tax);

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllPayouts(user.id as number, null, null, {
        ...initialFilterState,
        searchStatus: 'PENDING',
      });

      const capacity = user.payout_capacity - user.daily_payout;
      setCapacity(capacity);

      if (data) {
        setPendingPayouts(data.payouts);
      }
    };
    fetch();
  }, [activePayouts]);

  const checkboxOptions = [
    { label: 'Monobank', value: '1' },
    { label: 'PrivatBank', value: '2' },
    { label: 'OschadBank', value: '22' },
    { label: 'PUMB', value: '6' },
    { label: 'ABank', value: '5' },
    { label: 'UKRSIBBANK', value: '25' },
    { label: 'Credit Agricole', value: '12' },
    { label: 'Raiffeisen', value: '14' },
    { label: 'Credit Dnipro', value: '17' },
    { label: 'NovaPay', value: '4' },
    { label: t('other_bank'), value: '19' },
  ];

  const handleCheckboxChange = (value: string) => {
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    savePayoutBanks(selectedOptions)
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    if (!value) {
      setTransactionById(null);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearchBntClick();
    }
  };

  const onSearchBntClick = async () => {
    try {
      if (!user.id) {
        return;
      }
      const payout = await getPayoutByHashedId(searchValue, user.id);
      console.log('payout', payout[0]);
      if (payout[0]) {
        setTransactionById(payout[0]);
      } else {
        toast.info(t("not_found"));
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box sx={{ mb: 3, pl: isMobile ? 0 : 3, width: '100%' }}>
      <Typography level="h2" sx={{ p: '20px 0', fontWeight: 600, fontSize: isMobile ? '20px' : '28px' }}>
        {t('sidebar_nav_payout_settings')}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: isMobile ? 2 : 0 }}>
        <Typography level="h3" sx={{ p: '20px 0', fontWeight: 600 }}>
          {t('capacity')}: {Number(capacity).toFixed(2)}
        </Typography>
      </Box>
      <hr/>
      {user.base_currency === 'UAH' && (
        <><Box sx={{ mt: 1, mb: 4 }}>
          <Typography level="h3" sx={{ p: '20px 0', fontWeight: 600 }}>
            {t('payout_banks')}:
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: 2 }}>
              {checkboxOptions.map((option, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    value={option.value}
                    checked={selectedOptions.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)} />
                  <Typography sx={{ ml: 1 }}>{option.label}</Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ mt: 3, textAlign: 'left' }}>
              <Button
                type="submit"
                sx={{
                  background: 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                  color: '#f1f1f1',
                  borderRadius: '8px',
                  '&:hover': {
                    background: 'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
              >
                {t('settings_save_btn')}
              </Button>
            </Box>
          </form>
        </Box>
          <hr />
        </>
        )}
      <Typography level="h2" sx={{ p: '20px 0 ', fontWeight: 600 }}>
        {t('history_payout_search')}
      </Typography>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          gap: 1.5,
        }}
      >
        <FormControl sx={{ flex: 1, mb: 3, position: 'relative' }} size="sm">
          <Input
            sx={{
              padding: '12px 16px 12px 48px',
              width: !isMobile ? '430px':'100%',
              boxSizing: 'border-box',
              borderRadius: '8px',
              fontSize: '18px',
              color: '#868E96',
              borderColor: 'transparent',
              '--Input-placeholderOpacity': 1,
              '--Input-focusedInset': 'transparent',
            }}
            value={searchValue}
            placeholder={t('history_search_placeholder')}
            onChange={(e: { target: { value: string } }) => {
              handleSearchChange(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
          <IconButton variant='plain' sx={{
            position: 'absolute', p: 0, top: '50%',
            left: '12px',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer', '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
                      onClick={() => onSearchBntClick()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" fill="none"><path stroke="#F1F1F1" strokeLinecap="round" strokeLinejoin="round" d="M11 18.2a8 8 0 0 0 8-8.1 8 8 0 0 0-8-8.2 8 8 0 0 0-8 8.2 8 8 0 0 0 8 8.1Z" /><path stroke="#F1F1F1" strokeLinecap="round" d="m18 18 4 4" /></svg>
          </IconButton>
        </FormControl>
      </Box>

      {transactionById !== null && (
        <>
          <Typography level="h2" sx={{
          m: isMobile ? '20px 0 10px 0' : '20px 0 20px 0',
          fontWeight: 600,
          fontSize: isMobile ? '24px' : '28px',
          }}>
            {t('active_header_search_result')}
          </Typography>
          <Box
            className="SearchAndFilters-tabletUp"
            sx={{
              borderRadius: 'sm',
              display: {
                xs: 'none',
                sm: 'flex',
              },
              alignItems: 'flex-end',
              flexWrap: 'wrap',
              gap: 1.5,
              '& > *': {
                minWidth: {
                  xs: '120px',
                    md: '160px',
                },
              },
            }}
          >
          <SearchResultPayouts payouts={[transactionById]} />
        </Box>
          <SearchResultList list={[transactionById]} />
        </>
      )}

      <Typography level="h2" sx={{ m: isMobile ? '20px 0 10px 0' : '20px 0 20px 0', fontWeight: 600, fontSize: isMobile ? '24px': '28px' }}>
        {t('active_header_payout')}
      </Typography>
      <ActiveList list={activePayoutItems} />
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          display: {
            xs: 'none',
            sm: 'flex',
          },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <ActiveTrans payouts={activePayoutItems} />
      </Box>
      <Typography level="h2" sx={{ m: isMobile ? '20px 0  10px 0' : '80px 0  40px 0', fontWeight: 600, fontSize: isMobile ? '24px' : '28px' }}>
        {t('active_header_pending_payout')}
      </Typography>
      <PendingList list={pendingPayouts} />
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          display: {
            xs: 'none',
            sm: 'flex',
          },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <PendingPayout payouts={pendingPayouts} />
      </Box>
    </Box>
  );
};

export default ActivePayouts;
