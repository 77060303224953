import React, { useEffect, useState } from 'react';
import classes from './Settings.module.css';
import css from './Settings.module.css';
import { Box, Button, Card, CardActions, CardContent, Stack, Switch, switchClasses, Theme, Typography } from '@mui/joy';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { getGlobalUser } from '../../redux/auth/selectors';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LuCopy, LuCopyCheck } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { deleteMonoToken, generateApiKey, updateCallbackUrl, updateMonoToken } from '../../services/operators';
import { updateNotificationStatus, updateUserApi } from '../../redux/auth/operations';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import TokenList from './TokenList';
import useWidth from '../../hooks/useWidth';

const Settings = () => {
  const { t } = useTranslation();
  const { user } = useSelector(getGlobalUser);
  const [isCopied, setIsCopied] = useState(false);
  const [apiDisabled, setApiDisabled] = useState(false);
  const [showAPI, setShowAPI] = useState(false);
  const [showMonoTokens, setShowMonoTokens] = useState(user.mono_tokens || []);
  const [newMonoToken, setNewMonoToken] = useState(user.mono_token || '');
  const [newCallbackUrl, setNewCallbackUrl] = useState(user.operator_callback_url || '');
  const [notificationEnabled, setNotificationEnabled] = useState(user.is_notification_enabled);
  const { isMobile } = useWidth();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setShowMonoTokens(user.mono_tokens || []);
    setNewMonoToken(user.mono_token || '');
    setNewCallbackUrl(user.operator_callback_url || '');
    setNotificationEnabled(user.is_notification_enabled);
  }, [user]);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleChangeCheckbox = async () => {
    if (user?.id) {
      dispatch(updateNotificationStatus({
        id: user.id,
        is_notification_enabled: !notificationEnabled
      }));
      setNotificationEnabled(!notificationEnabled);
    }
  };

  const handleGenerateApi = async () => {
    const response = await generateApiKey();
    if (response && user.id) {
      await dispatch(updateUserApi({ id: user.id, mobile_token: response.mobile_token }));
      setApiDisabled(true);
      toast.success(t('toast_generateApi_success'));
    }
  };

  const handleUpdateMonoTokenApi = async () => {
    if (user.id) {
      await updateMonoToken(newMonoToken);
      toast.success(t('toast_monoToken_success'));
      setShowMonoTokens(prevTokens => [...prevTokens, newMonoToken]);
      setNewMonoToken('');
    }
  };

  const handleUpdateCallbackUrl = async () => {
    if (user.id) {
      await updateCallbackUrl(newCallbackUrl);
      toast.success(t('toast_callback_success'));
      setNewCallbackUrl(newCallbackUrl);
    }
  };

  const handleDeleteMonoTokenApi = async (tokenToDelete: string) => {
    if (user.id) {
      await deleteMonoToken(tokenToDelete);
      toast.success(t('toast_monoToken_deleted_success'));
      setShowMonoTokens((prevTokens: any[]) => prevTokens.filter((token: string) => token !== tokenToDelete));
    }
  };

  const handleToggleVisibility = () => {
    setShowAPI(showAPI => !showAPI);
    setIsCopied(false);
  };

  const obscuredAPI = showAPI ? user?.mobile_token : '*'.repeat(user?.mobile_token?.length);

  return (
    <Box sx={{ mb: 3, p: 3, width: '100%' }}>
      <Stack spacing={3}>
        <Typography level="h2">{t('settings_header')}</Typography>
      </Stack>
      <CardContent>
        <Card sx={{ mt: 3 }}>
          <Typography level="h4">{t('platform_account_settings')}</Typography>
          <Typography level="body-xs" sx={{ textAlign: 'left' }}>
            {t('platform_account_notifications')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
            <Switch
              color={notificationEnabled ? 'primary' : 'danger'}
              size="lg"
              variant={'solid'}
              checked={notificationEnabled}
              onChange={handleChangeCheckbox}
              sx={(theme: Theme) => ({
                '--Switch-thumbSize': '27px',
                '--Switch-trackWidth': '53px',
                '--Switch-trackHeight': '31px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '32px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                  '&:hover': {
                    '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                  },
                },
              })}
            />
          </Box>
        </Card>
      </CardContent>
      <Box sx={{ display: (isMobile && 'block') || 'flex', gap:'20px' }}>
        <CardContent>
          <Card sx={{ mt: 3 }}>
            <Typography level="h4">{t('settings_api_header')}</Typography>
            <Card sx={{ mt: 3 }}>
              <div
                className={classes.box}
                style={{
                  overflowWrap: 'anywhere',
                  overflow: 'hidden',
                }}
              >
                {obscuredAPI}
                <Box sx={{ display: 'flex' }}>
                  <button className={css.buttonApi} type="button" onClick={handleToggleVisibility}>
                    {showAPI ? (
                      <AiOutlineEyeInvisible style={{ width: '24px', height: '24px' }} />
                    ) : (
                      <AiOutlineEye style={{ width: '24px', height: '24px' }} />
                    )}
                  </button>
                  <CopyToClipboard text={user.mobile_token} onCopy={onCopyText}>
                  <span className={classes.iconCopy}>
                    {isCopied ? <LuCopyCheck size={17} /> : <LuCopy size={17} />}
                  </span>
                  </CopyToClipboard>
                </Box>
              </div>
              <CardActions>
                <Button variant="outlined" sx={{
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)', color: '#f1f1f1',
                  borderRadius: '8px',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }} onClick={handleGenerateApi} disabled={apiDisabled}>
                  {t('settings_regenerate_api')}
                </Button>
              </CardActions>
            </Card>
          </Card>
        </CardContent>

        <CardContent>
          <Card sx={{ mt: 3, height:'100%' }}>
            <Typography level="h4">{t('settings_api_callback')}</Typography>
            <Card sx={{ mt: 3, height:'100%' }}>
              <input
                className={classes.box}
                type="text"
                value={newCallbackUrl}
                onChange={(e) => setNewCallbackUrl(e.target.value)}
              >
              </input>
              <CardActions>
                <Button variant="outlined" sx={{
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)', color: '#f1f1f1',
                  borderRadius: '8px',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }} onClick={handleUpdateCallbackUrl}>
                  {t('settings_save_callback')}
                </Button>
              </CardActions>
            </Card>
          </Card>
        </CardContent>
      </Box>
      <Box sx={{ display: (isMobile && 'block') || 'flex', gap:'20px' }}>

      <CardContent>
        <Card sx={{ mt: 3 }}>
          <Typography level="h4">{t('settings_mono_token_header')}</Typography>
          <Card sx={{ mt: 3 }}>
            <input
              className={classes.box}
              type="text"
              value={newMonoToken}
              onChange={(e) => setNewMonoToken(e.target.value)}
            />
            <CardActions>
              <Button variant="outlined" sx={{
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)', color: '#f1f1f1',
                borderRadius: '8px',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                },
              }} onClick={handleUpdateMonoTokenApi}>
                {t('settings_regenerate_mono_token')}
              </Button>
            </CardActions>
          </Card>
        </Card>
      </CardContent>
      <CardContent>
        <Card sx={{ mt: 3, height:'100%' }}>
          <Typography level="h4">{t('settings_mono_token_list')}</Typography>
          <Card sx={{ mt: 3 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {showMonoTokens && showMonoTokens.length > 0 ? (
                showMonoTokens.map((token, index) =>  (
                  <TokenList
                    key={index}
                    token={token}
                    handleDeleteMonoTokenApi={handleDeleteMonoTokenApi}
                  />
                ))
              ) : (
                <div>{t('settings_mono_tokens_empty')}</div>
              )}
            </div>
          </Card>
        </Card>
      </CardContent>
      </Box>
    </Box>
  );
};

export default Settings;
