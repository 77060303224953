import React, { useState } from 'react';
import { Typography, Box, Button, Sheet, Modal, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import IconComponent from '../../components/IconComponent/IconComponent';
import css from './ActivePayouts.module.css';
import { Payout } from '../../utils/types';
import { LuCopy, LuCopyCheck } from 'react-icons/lu';
import CopyToClipboard from 'react-copy-to-clipboard';
import ModalCancel from './ModalCancel';
import { cancelPayout } from '../../services/operators';

const ModalConfirm = ({
  openModal,
  toggleOpenModal,
  onSubmit,
  handleChange,
  handleChangeReceiptUrl,
  payout,
  handleChangeImage,
  isSearch = false,
}: {
  openModal: boolean;
  toggleOpenModal: () => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (value: string) => void;
  handleChangeReceiptUrl: (value: string) => void;
  payout: Payout;
  handleChangeImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSearch?: boolean;
}) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const { isMobile } = useWidth();

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleChangeCancelReason = async (value: string) => {
    setCancelReason(value);
  };

  const cancel = async () => {
    await cancelPayout(payout.id, cancelReason);
  };

  const onSubmitCancel = () => {
    cancel();
  };

  const toggleOpenCancelModal = async () => {
    if (cancelReason) {
      onSubmitCancel();
    }

    setOpenModalCancel(!openModalCancel);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(event.target.files);
    handleChangeImage(event);
  };

  let type = '';
  let label = '';
  let card = '';
  let iban = '';
  const pocketAddress = payout.pocket_address.replace(/\s/g, '');

  if (pocketAddress.length > 34 && pocketAddress.includes(';')) {
    type = 'both';
    const separateArray = pocketAddress.split(';').map((item) => item.trim());
    card = separateArray[0];
    iban = separateArray[1];
  } else if (pocketAddress.length <= 19 && pocketAddress.length >= 16 && !pocketAddress.includes('UA')) {
    type = 'card';
    label = 'transaction_card';
  } else if (pocketAddress.includes('UA') && pocketAddress.length === 29) {
    type = 'iban'
    label = 'transaction_iban';
  }

  return (
    <Modal
      open={openModal}
      onClose={toggleOpenModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'transparent',
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '600px',
          borderRadius: '16px',
          padding: '32px 40px',
          boxShadow: '4px 2px 64px 0 rgba(69, 69, 69, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          border: 'transparent',
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <IconButton onClick={toggleOpenModal} sx={{ marginLeft: 'auto' }}>
          <IconComponent
            paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
        </IconButton>
        <Typography
          level="h3"
          sx={{
            textAlign: 'center',
            mb: 3,
            color: '#F1F1F1',
          }}
        >
          {t('modalPayoutDetails')}
        </Typography>
        <Typography
          level="h3"
          sx={{
            mb: 3,
            color: '#F1F1F1',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {payout.currency === 'UAH' ? (
              <span>
              {type !== 'both' && (
                t(`${label}`)
              )}

                {type === 'card' || type === 'iban' ? (
                  <b>: {payout.pocket_address}</b>
                ) : type === 'both' ? (
                  <div>
                    <b>{t('transaction_card')}: {card}</b><br />
                    <b>{t('transaction_iban')}: {iban}</b>
                  </div>
                ) : null}
              </span>
            ) : (
              <b>{t('transaction_card')}: {pocketAddress}</b>
            )}
            <CopyToClipboard text={payout.pocket_address} onCopy={onCopyText}>
              <span style={{
                padding: '4px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#fff',
                transition: 'background-color 0.3s ease',
                marginLeft: '4px',
              }}>
                {isCopied ? <LuCopyCheck size={17} /> : <LuCopy size={17} />}
              </span>
            </CopyToClipboard>
          </div>
        </Typography>
        {payout.ipn && (
          <Typography
            level="h4"
            sx={{
              color: '#F1F1F1',
            }}
          >
            {t('payout_ipn')}: {payout.ipn}
          </Typography>
        )}
        {payout.pib && (
          <Typography
            level="h4"
            sx={{
              color: '#F1F1F1',
            }}
          >
            {t('payout_pib')}: {payout.pib}
          </Typography>
        )}
        {payout.same_bank_only && (
          <Typography
            level="h4"
            sx={{
              color: '#F1F1F1',
            }}
          >
            {t('payout_same_bank')}: { payout.same_bank_only ? t('yes') : t('no') }
          </Typography>
        )}
        {payout.allow_partitions && (
          <Typography
            level="h4"
            sx={{
              color: '#F1F1F1',
            }}
          >
            {t('allow_partitions')}: { payout.allow_partitions ? t('yes') : t('no') }
          </Typography>
        )}
        <Typography
          level="h4"
          sx={{
            color: '#F1F1F1',
          }}
        >
          {t('card_table_bank')}: {payout.bank_name}
        </Typography>
        <Typography
          level="h4"
          sx={{
            color: '#F1F1F1',
          }}
        >
          {t('active_table_price', { currency: localStorage.getItem('base_currency') })}: {Number(payout.amount).toFixed(2)}
        </Typography>
        <Typography
          level="h4"
          sx={{
            color: '#F1F1F1',
          }}
        >
          {t('history_table_priceUsdt')}: {Number(payout.operator_amount).toFixed(2)}
        </Typography>
        <Typography
          level="h4"
          sx={{
            color: '#F1F1F1',
          }}
        >
          {t('order_id')}: {payout.order_id}
        </Typography>
        <br />
        {(payout.status === 'PROCESSING' || isSearch) && (
          <form onSubmit={onSubmit}>
            <label>{t('payout_receipt_number')}</label>
            <input
              onChange={(e) => handleChange(e.target.value)}
              id="receipt_number"
              name="receipt_number"
              placeholder={'3MP3-TMBH-0E8E-8MCM'}
              autoFocus
              className={css.input}
            />
            <label>{t('payout_receipt_link')}</label>
            <input
              onChange={(e) => handleChangeReceiptUrl(e.target.value)}
              id="receipt_url"
              name="receipt_url"
              placeholder={'https://filetransfer.io/data-package/cZ0ioqD#link'}
              autoFocus
              className={css.input}
            />
            <Box sx={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}>
              <input
                type="file"
                accept="*"
                multiple={true}
                onChange={handleFileChange}
                className={css.inputFile}
                id="file_input"
              />
              <label htmlFor="file_input" className={css.customFileUpload}>
                {t('payout_choose_files')}
              </label>
            </Box>
            {selectedFiles && (
              <Box sx={{ marginTop: '5px', marginBottom: '10px', color: '#F1F1F1' }}>
                {Array.from(selectedFiles).map((file, index) => (
                  <Typography key={index} sx={{ color: '#F1F1F1' }}>
                    {file.name}
                  </Typography>
                ))}
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Button
                type="submit"
                sx={{
                  width: isMobile ? '100%' : '300px',
                  background: 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                  color: '#FFF',
                  '&:hover': {
                    background: 'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                    color: '#f1f1f1',
                  },
                }}
              >
                {t('sidebar_modal_btn')}
              </Button>
              <Button
                type="button"
                sx={{
                  width: isMobile ? '100%' : '300px',
                  background: 'linear-gradient(135deg, rgba(221, 79, 79, 1) 0%, rgba(172, 47, 47, 1) 100%)',
                  color: '#FFF',
                  '&:hover': {
                    background: 'linear-gradient(135deg, rgba(221, 79, 79, 0.8) 0%, rgba(172, 47, 47, 0.8) 100%)',
                    color: '#f1f1f1',
                  },
                }}
                onClick={toggleOpenCancelModal}
              >
                {t('withdrawalBtn')}
              </Button>
            </Box>
          </form>
        )}
        <ModalCancel
          openModalCancel={openModalCancel}
          toggleOpenCancelModal={toggleOpenCancelModal}
          onSubmitCancel={onSubmitCancel}
          handleChangeCancelReason={handleChangeCancelReason}
        />
      </Sheet>
    </Modal>
  );
}

export default ModalConfirm;
