import { Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
// import Checkbox from '@mui/joy/Checkbox';
import { Transactions } from '../../utils/types';
import HistoryItem from './HistoryItem';
import { useTranslation } from 'react-i18next';

const HistoryTrans = ({ notifications }: { notifications: Transactions[] }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<readonly number[]>([]);

  return (
    <>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',

        }}
      >
        <thead >
          <tr>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_name')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_card')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_priceUah', {
              currency: (localStorage.getItem('base_currency')) || '',
            })}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_priceUsdt')}</th>
            <th colSpan={2} style={{ color: '#F1F1F1' }}>{t('history_table_Date')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_status')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('history_table_actions')}</th>
          </tr>
        </thead>
        <tbody>
          {notifications &&
            notifications?.map((notification: Transactions) => (
              <HistoryItem
                key={notification.id}
                notification={notification}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
        </tbody>
      </Table>
      {!notifications && <Typography sx={{ mt: 2, pl: 2, }}>{t('not_found')}</Typography>}
    </>
  );
};
export default HistoryTrans;
